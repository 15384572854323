import { CommonSettings } from '@starsoft/common/interfaces';
import { useSettingsStore } from '../useSettingsStore';
import { UseTranslationReturn } from '@starsoft/common/interfaces/translation-return';

export function useTranslation(namespace?: string) {
  const { state } = useSettingsStore();
  const settings: CommonSettings = state.settings;
  const { t, i18n, lang }: UseTranslationReturn =
    settings.useTranslation(namespace);
  return {
    t,
    i18n,
    lang: lang ?? i18n?.language,
  };
}
